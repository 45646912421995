import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { LoginService } from './../../login/login.service';

@Injectable({
  providedIn: 'root'
})
export class RouteGuard  {

  constructor(
    private loginService: LoginService,
    private router: Router
  ) {
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.loginService.user.pipe(
      take(1),
      map(user => {
        if (!!user) {
          if (next.url && next.url[0].path === 'forgotpassword') {
            this.router.navigate(['forgotpassword/verification'],
              {
                queryParams: { email: user.email, isUpdate: true, returnUrl: '/useraccount/accountinfo' },
                queryParamsHandling: 'merge'
              });
          } else if (next.url && (next.url[0].path === 'register' || next.url[0].path === 'login')) {
            this.router.navigate(['useraccount/accountinfo']);
          }
          return true;
        } else {
          return true;
        }
      })
    );

  }

}
